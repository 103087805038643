import { useContext } from 'react'

import { PropertyContext } from '../../components/providers'

export const usePropertyProvider = () => {
  const context = useContext(PropertyContext)
  if (!context) {
    throw new Error(
      `Property compound components cannot be rendered outside the Property component`
    )
  }
  return context
}
