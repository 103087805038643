import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './PlanIndexFrame.module.scss'

import {
  BannerHurusatoNouzeiSmall,
  ButtonWrapper as Button,
  FacilityOptionPlanPanel,
  MainColumn,
  MultiColumn,
  OptionPlanPanel,
  PlanIndex,
  SearchLayout,
  SearchMainContent,
  SideColumn,
} from '../../components'
import { PATH } from '../../constants'
import { useCreateNextPath, usePropertyProvider } from '../../hooks'

const translateNamespace = 'frames.PlanFrame'

export const PlanIndexFrame = () => {
  const { t } = useTranslation()
  const { isHotel, isActivity } = usePropertyProvider()
  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()
  return (
    <SearchLayout>
      <SearchMainContent>
        <MultiColumn>
          <SideColumn>
            {isHotel ? <OptionPlanPanel /> : <FacilityOptionPlanPanel />}
            <div className={styles.bannerArea}>
              {isActivity ? null : <BannerHurusatoNouzeiSmall />}
            </div>
          </SideColumn>
          <MainColumn>
            <PlanIndex />
          </MainColumn>
        </MultiColumn>
      </SearchMainContent>
      <div className={styles.backButton}>
        <Button
          htmlType="button"
          variant="tertiary"
          outline
          size="large"
          position="center"
          onClick={() => {
            const nextPath = onCreateNextPath(PATH.rooms)
            navigate(nextPath)
          }}
        >
          {isHotel
            ? t(`${translateNamespace}.backToRoom`)
            : t(`${translateNamespace}.backToTicket`)}
        </Button>
      </div>
    </SearchLayout>
  )
}
