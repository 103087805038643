import useSWR from 'swr'

import { tokenOptions, transactionApi } from '../../apis/suitebookClient'
import { DEFAULT_TRANSACTION_ID } from '../../constants'

export const useTransactionApi = (transactionId?: number, token?: string) => {
  const options = tokenOptions(token ?? '')
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    transactionId && transactionId !== DEFAULT_TRANSACTION_ID
      ? `/transactions/${transactionId}`
      : undefined,
    transactionId
      ? () =>
          transactionApi
            .getTransaction(transactionId, options)
            .then((res) => res.data)
      : null,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
