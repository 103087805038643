import {
  addDays as dateFnsAddDays,
  differenceInDays,
  getHours,
  max,
  parse,
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

import { TIMEZONE } from '../constants'

export const getCurrentJstDateTime = () => utcToZonedTime(new Date(), TIMEZONE)

export const addJstDate = (date: Date | string, amount: number) => {
  const zoneTime = utcToZonedTime(date, TIMEZONE)
  return format(dateFnsAddDays(zoneTime, amount), 'yyyy-MM-dd', {
    timeZone: TIMEZONE,
  })
}

type Props = {
  addDays?: number
}

export const getCurrentJstDate = (props?: Props) => {
  const current = utcToZonedTime(new Date(), TIMEZONE)
  if (props && props.addDays) {
    return addJstDate(current, props.addDays)
  }
  return format(current, 'yyyy-MM-dd', {
    timeZone: TIMEZONE,
  })
}

export const getNights = (checkInDate?: string, checkOutDate?: string) => {
  if (checkInDate && checkOutDate) {
    return differenceInDays(
      parse(checkOutDate, 'yyyy-MM-dd', new Date()),
      parse(checkInDate, 'yyyy-MM-dd', new Date())
    )
  }
  return null
}

export const getDefaultCheckInDate = (pathName?: string) => {
  const current = getCurrentJstDateTime()
  const checkInDate = dateFnsAddDays(current, getHours(current) >= 22 ? 1 : 0)

  if (
    !process.env.VITE_DEFAULT_CHECK_IN_DATE ||
    process.env.VITE_DEFAULT_CHECK_IN_DATE === 'undefined'
  ) {
    return format(checkInDate, 'yyyy-MM-dd', {
      timeZone: TIMEZONE,
    })
  }

  let defaultCheckInDate = process.env.VITE_DEFAULT_CHECK_IN_DATE
  if (
    pathName?.includes('/ice-park') &&
    process.env.VITE_DEFAULT_ICE_PARK_CHECK_IN_DATE &&
    process.env.VITE_DEFAULT_ICE_PARK_CHECK_IN_DATE !== 'undefined'
  ) {
    defaultCheckInDate = process.env.VITE_DEFAULT_ICE_PARK_CHECK_IN_DATE
  }
  const defaultDate = parse(
    defaultCheckInDate,
    'yyyy-MM-dd',
    utcToZonedTime(new Date(), TIMEZONE)
  )

  return format(max([defaultDate, checkInDate]), 'yyyy-MM-dd', {
    timeZone: TIMEZONE,
  })
}
