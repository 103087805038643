import { useContext } from 'react'

import { AuthContext } from '../../components/providers'

export const useAuthProvider = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(
      `Auth compound components cannot be rendered outside the Auth component`
    )
  }
  return context
}
