import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { utcToZonedTime } from 'date-fns-tz'

import * as styles from './FacilitySearchModal.module.scss'

import { TIMEZONE } from '../../../constants'
import { FacilityPerson, facilityPersonSchema } from '../../../schemas'
import { formatJstDate } from '../../../utils'
import {
  Button,
  Flex,
  FlexBox,
  FormSelect,
  List,
  Modal,
  ModalProps,
  Option,
  ReactHookFormErrorMessage,
  Text,
} from '../../basics'

const translateNamespace = 'components.FacilitySearchModal'

type FacilitySearchModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  propertyName: string
  onSearch: (
    rooms: number,
    adults: number,
    children: number,
    infants: number
  ) => void
  roomParam?: number
  adultParam?: number
  childParam?: number
  infantParam?: number
  checkIn?: string
  isActivity?: boolean
}

export const FacilitySearchModal: React.FC<FacilitySearchModalProps> = ({
  isOpen,
  onClose,
  propertyName,
  onSearch,
  roomParam = 1,
  adultParam = 2,
  childParam = 0,
  infantParam = 0,
  checkIn = '',
  isActivity = false,
}) => {
  const { t, i18n } = useTranslation()
  const [room, setRoom] = useState(roomParam)
  const [adult, setAdult] = useState(adultParam)
  const [child, setChild] = useState(childParam)
  const [infant, setInfant] = useState(infantParam)
  const checkInDate = utcToZonedTime(checkIn, TIMEZONE)

  const items = [
    {
      label: `${t(`${translateNamespace}.property`)}`,
      text: `${propertyName}`,
    },
    {
      label: `${t(`${translateNamespace}.dateOfUse`)}`,
      text: `${formatJstDate(
        checkInDate,
        i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
      )}`,
    },
    {
      label: `${t(`${translateNamespace}.guestAmount`)}`,
      text: (
        <Trans
          i18nKey={`${translateNamespace}.guests`}
          value={{
            adult,
            child,
            infant,
          }}
        >
          {{ adult }} adults
          <br />
          {{ child }} children
          <br />
          {{ infant }} infants
        </Trans>
      ),
    },
  ]

  const disabledSearch = useMemo(() => room < 1, [room])

  const formMethods = useForm<FacilityPerson>({
    defaultValues: { adult, child, infant },
    resolver: zodResolver(facilityPersonSchema),
  })
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods

  const validate = useCallback(() => {
    const values = formMethods.getValues()

    const adultVal = Number(values.adult)
    const childVal = Number(values.child)
    const infantVal = Number(values.infant)
    const total = adultVal + childVal + infantVal

    clearErrors()

    if (!isActivity) {
      if (total < 1) {
        setError('adult', { message: t('validations.person') })
      }
      return
    }

    if (infantVal > 0 && adultVal < 1) {
      setError('adult', { message: t('validations.adult') })
      return
    }

    if (adultVal < 1 && childVal < 1) {
      setError('adult', { message: t('validations.person') })
      return
    }

    if (total < 1) {
      setError('adult', { message: t('validations.person') })
    }
  }, [setError, t, formMethods, isActivity, clearErrors])

  const isError = Object.keys(errors).length > 0

  const onSubmit = handleSubmit((formValues) => {
    onSearch(room, formValues.adult, formValues.child, formValues.infant)
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setRoom(roomParam)
        setAdult(adultParam)
        setChild(childParam)
        onClose()
      }}
      title={t(`${translateNamespace}.selectTicket`)}
      leftButton={
        <Button
          htmlType="button"
          variant="secondary"
          onClick={() => {
            setRoom(roomParam)
            setAdult(adultParam)
            setChild(childParam)
            onClose()
          }}
        >
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={disabledSearch || isError}
          onClick={onSubmit}
        >
          {t(`${translateNamespace}.search`)}
        </Button>
      }
    >
      <Flex optionClass={styles.contents}>
        <FlexBox optionClass={styles.formColumn}>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>
                {t(
                  isActivity
                    ? `${translateNamespace}.adult`
                    : `${translateNamespace}.adultWithAge`
                )}
                {isActivity && (
                  <React.Fragment>
                    <br />
                    <span className={styles.ageInfo}>
                      {t(`${translateNamespace}.adultInfo`)}
                    </span>
                  </React.Fragment>
                )}
                {'　'}
              </span>
            </FlexBox>
            <FlexBox>
              <FormSelect
                onChange={(e) => {
                  setAdult(Number(e.target.value))
                  validate()
                }}
                value={adult}
                name="adult"
                control={formMethods.control}
              >
                {[...Array(isActivity ? 16 : 30)].map((_, i) => {
                  const val = isActivity ? i : i + 1
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Option value={val} key={`room-modal-adult${val}`}>
                      {val} {t(`${translateNamespace}.guestUnit`)}
                    </Option>
                  )
                })}
              </FormSelect>
            </FlexBox>
          </Flex>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>
                {t(
                  isActivity
                    ? `${translateNamespace}.toddler`
                    : `${translateNamespace}.toddlerWithAge`
                )}
                {isActivity && (
                  <React.Fragment>
                    <br />
                    <span className={styles.ageInfo}>
                      {t(`${translateNamespace}.toddlerInfo`)}
                    </span>
                  </React.Fragment>
                )}
                {'　'}
              </span>
            </FlexBox>
            <FlexBox>
              <FormSelect
                onChange={(e) => {
                  setChild(Number(e.target.value))
                  validate()
                }}
                value={child}
                name="child"
                control={formMethods.control}
              >
                {[...Array(16)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i} key={`room-modal-child${i}`}>
                    {i} {t(`${translateNamespace}.guestUnit`)}
                  </Option>
                ))}
              </FormSelect>
            </FlexBox>
          </Flex>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>
                {t(
                  isActivity
                    ? `${translateNamespace}.infant`
                    : `${translateNamespace}.infantWithAge`
                )}
                {isActivity && (
                  <React.Fragment>
                    <br />
                    <span className={styles.ageInfo}>
                      {t(`${translateNamespace}.infantInfo`)}
                    </span>
                  </React.Fragment>
                )}
                {'　'}
              </span>
            </FlexBox>
            <FlexBox>
              <FormSelect
                onChange={(e) => {
                  setInfant(Number(e.target.value))
                  validate()
                }}
                value={infant}
                name="infant"
                control={formMethods.control}
              >
                {[...Array(16)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i} key={`room-modal-infant${i}`}>
                    {i} {t(`${translateNamespace}.guestUnit`)}
                  </Option>
                ))}
              </FormSelect>
            </FlexBox>
          </Flex>
          <div className={clsx(styles.error, styles.forms)}>
            <ReactHookFormErrorMessage errors={errors} name="adult" />
          </div>
          {isActivity && (
            <div className={styles.notice}>
              <Text size={12}>
                {t(`${translateNamespace}.notice`)}
                <br />
                <a
                  href="https://www.hkdballpark.com/news/564/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(`${translateNamespace}.activityDetail`, {
                    name: propertyName,
                  })}
                </a>
              </Text>
            </div>
          )}
        </FlexBox>
        <FlexBox>
          <div className={styles.list}>
            <List
              items={items}
              renderItem={(item) => (
                <List.Item key={item.label}>
                  <div className={styles.listItem}>
                    <Flex optionClass={styles.listItemContents}>
                      <FlexBox>
                        <Text size={10}>{item.label}</Text>
                      </FlexBox>
                      <FlexBox>
                        <Text weight="bold" size={12}>
                          {item.text}
                        </Text>
                      </FlexBox>
                    </Flex>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </FlexBox>
      </Flex>
    </Modal>
  )
}
