import React, { useCallback, useMemo } from 'react'

import { AuthContext } from './AuthContext'

import { PATH } from '../../../constants'
import { useLogin } from '../../../hooks/useLogin'

type AuthProviderProps = {
  children: React.ReactNode
}

const LOGIN_URL = process.env.VITE_OPENID_CONNECT_URL

const useAuth = () =>
  useMemo(() => {
    const data = {
      token: '',
      name: '',
      kana: '',
      email: '',
      phone: '',
      postalCode: '',
      prefecture: '',
      address1: '',
      address2: '',
      address3: '',
      dateOfBirth: '',
    }
    try {
      data.token = sessionStorage.getItem('access_token') || ''
      data.name = sessionStorage.getItem('name') || ''
      data.kana = sessionStorage.getItem('kana') || ''
      data.email = sessionStorage.getItem('email') || ''
      data.phone = sessionStorage.getItem('phone') || ''
      data.postalCode = sessionStorage.getItem('postalCode') || ''
      data.prefecture = sessionStorage.getItem('prefecture') || ''
      data.address1 = sessionStorage.getItem('address1') || ''
      data.address2 = sessionStorage.getItem('address2') || ''
      data.address3 = sessionStorage.getItem('address3') || ''
      data.dateOfBirth = sessionStorage.getItem('dateOfBirth') || ''
    } catch (e) {
      // Do Nothing
    }

    return data
  }, [])

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { onLogin } = useLogin()
  const context = useAuth()
  const isRedirectToLoginPage = useCallback(() => {
    if (!LOGIN_URL) {
      return false
    }

    const loginModalPages = [
      PATH.reservations.hotels.root,
      PATH.reservations.spas.root,
      PATH.reservations.activities.root,
    ]
    if (loginModalPages.includes(window.location.pathname)) {
      return false
    }

    return !context.token
  }, [context.token])

  if (isRedirectToLoginPage()) {
    onLogin()
    return null
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
